/*
 * Flex UX Framework
 *
 * Filename: nav_menu.scss
 * Type:     Component Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

// footer 

.footer-nav-menu {
	display: flex;
	align-items: stretch;
	margin: 0px;
	height: 100%;

	li {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		list-style-type: none;
		font-size: 13px;
		margin: 0px 10px;
		cursor: pointer;
		font-weight: 700;

		@include mq("tablet-small", "max") {
			margin-bottom: 10px;
		}

		a {
			display: flex;
			justify-content: center;
			align-items: center;
			//adding: 0 14px;
			height: 100%;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	@include mq("tablet", "max") {
	    padding: 0;
	    padding-top: 25px;
	}
	@include mq("tablet-small", "max") {
	    display: inline-block;
	}
}