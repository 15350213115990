/*
 * Flex UX Framework
 *
 * Filename: product_tabs.scss
 * Type:     Component Stylesheet
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.products-tab-bar {
    margin: 12px;
    //margin-top: 24px;
    margin-top: 0px;
    margin-bottom: 12px;
    background: white;
    height: 52px;
    margin-left: auto;
    margin-right: auto;
    max-width: 948px;
    border: none;

  a.mdl-tabs__tab {
    font-family: $secondary-font-face;
		font-size: 16px;
		font-weight: 700;
		letter-spacing: 1px;
		color: $primary-font-color;
		height: 100%;
		line-height: 52px;
    padding: 0px 10px;
    pointer-events: none;
    //&.is-active{
    //  background: red;
    //}
  }

  .mdl-tabs__tab {
    padding: 0px 15px;
    transition: background 0.15s;
    font-size: 18px;
    font-family: $secondary-font-face;
    background-color: #FFF;
    border: 0;
    color: $primary-font-color;
  }

  .mdl-tabs__tab::after {
    background: $brand-color !important;
    height: 3px !important;
  }

  .mdl-tabs__tab:hover {
    background-color: #f7f7f7;
  }

}

.container{
    background: white;
}

.dropdown-container {
  position: relative;
  height:52px;
  width: 100%;
  .dropdown-arrow {
    position: absolute;
    right: 15px;
    top: 12px;
    pointer-events: none;
    transform: rotate(90deg);
    color: $brand-color;
  }

  select {
    height: 52px;
    line-height: 52px;
    width: 100%;
    padding: 0;
    font-size: 17px;
    font-weight: bold;
    text-transform: uppercase;
    border: none;
    background: white;
    cursor: pointer;
    text-align: center;
    text-align-last: center;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    padding-left: 15px;
    width: calc(100% - 15px);
  }
}
.products-tab-panels {
  margin: 0 -7px;
}

