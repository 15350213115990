/*
 * Flex UX Framework
 *
 * Filename: home-spotlight-v1.scss
 * Type:     Partial Stylesheet
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

 .home {
	.page-body {
		max-width: 972px;
		margin: 0px auto auto;
		padding: 12px;
		background: inherit;

		@include mq("phone-wide", "max") {
			padding: 6px;
			margin-top: 0;
		}
	}
}

.hero-slide {
	display: flex;
	justify-content: center;

	.content {
		display: flex;
		align-items: center;
		justify-content: start;
		margin-left: 200px;
		width: 100%;

		@include mq("desktop-wide", "max") {
			margin-left: 120px;
		}
		@include mq("desktop", "max") {
			margin-left: 50px;
		}
		@include mq("tablet", "max") {
			justify-content: center;
			margin-left: 0px;
		}

		span {
			width: 100%;

			.text-content {
				// padding-bottom: 5vw;
				padding-left: 43px;
				// width: 40%;
				height: 100%;
				display: flex;
				flex-flow: column;

				@include mq('tablet', 'max') {
					padding-left: 0;
				}


				// @include mq("tablet-small", "max") {
				// 	padding-bottom: 8vw;
				// 	padding-left: 10vw;
				// 	width: 60%;
				// }

				h1 {
					color: #FFFFFF;
					font-size: 115px;
					line-height: auto;
					//font-size: calc(12px + 3.6vw);
					// font-size: calc(14px + 4vw);
					font-weight: 400;
					font-family: 'anton', 'sans-serif';
					text-shadow: -1px 1px 4px rgba(0, 0, 0, 0.75);
					border-bottom: 4px solid #fff;
					margin-top: auto;
					margin-bottom: 0;
					width: 300px;
					padding-bottom: 15px;
					box-shadow: 0 6px 6px -6px rgba(0, 0, 0, 0.75);

					@include mq('tablet', 'max') {
						margin: auto;
						// font-size: calc(100px + 4vw);
					}

					@include mq('phone', 'max') {
						font-size: 24vw;
						text-align: center;
						width: auto;
						padding-bottom: 8px;
					}

					span {
						color: #000;

						&:last-of-type {
							color: #D94035;
						}
					}

				}

				h2 {
					color: #FFFFFF;
					font-weight: 400;
					font-size: 24px;
					line-height: 1.3;
					//font-size: calc(12px + .6vw);
					// font-size: calc(12px + 1vw);
					padding-top: 22px;
					text-shadow: 0 0 6px rgba(0, 0, 0, 0.75);

					@include mq('tablet', 'max') {
						margin: auto;
					}

					@include mq('phone', 'max') {
						padding-top: 10px;
						font-size: calc(24px - 1vw);
					}
				}

				button {
					width: 195px;
					margin-top: 48px;

					@include mq('tablet', 'max') {
						margin-left: auto;
						margin-right: auto;
					}

					@include mq('tablet-small', 'max') {
						margin-top: 3vw;
					}

					@include mq('phone', 'max') {
						margin-top: 2vw;
					}
				}
			}

		}
	}
}

//.spotlight {
//	.page-header {
//		position: relative;
//		margin-top: 0px;
//		padding-top: $header-height;

//		@include mq("tablet", "max") {
//    		padding-top: 56px;
//		}
//	}
//}

.spotWelcomeBox {
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	margin: 0 auto;

	align-items: center;

	@include mq("phone-smallest", "min") {
		width: 100%;
		height: 140px;
	}

	@include mq("tablet-small", "min") {
		width: 80%;
		height: 200px;
	}

}

.spotWelcomeBoxText {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	padding: 2vw 2.5vw;
	margin: 0;
	width: 700px;
	background-color: transparent;
	
	@include mq("tablet", "max") {
		align-items: center;
	}

	h1 {
		margin-top: 0;
		margin-bottom: 31px;
		padding-right: 70px;
		color: #fff;
		font-size: calc(30px + 1.25vw);
		font-weight: 700;
		line-height: 1;
		text-align: left;
		text-shadow: 2px 3px 8px #3e3e3e;
		@media only screen and (max-width: 1663px) {
			padding-right: 150px;
		}
		@include mq("desktop-wide", "max") {
			padding-right: 200px;
		}
		@include mq("desktop", "max") {
			padding-right: 230px;
		}
		@include mq("tablet", "max") {
			padding-right: 30px;
			text-align: center;
			padding-left: 30px;
		}
	}
}