/*
 * Flex UX Framework
 *
 * Filename: login-box.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

 .login {
 	main {
    	background-color: #fff !important;
    }
 }

.login-box {
    font-family: $primary-font-face;

    label {
		text-align: left;
	    /*padding-left: 10px;*/
    }

    input {
	    text-align: left;
	    font-family: $primary-font-face;
	    font-size: 14px;   
	}

	a {
	    font-size: 14px;
	    //color: #00843D;
	    text-decoration: underline;
	}

	.mdl-grid {
	    justify-content: center;
	    text-align: center;
	    align-items: center;
	    margin: 0px auto !important;
        padding: 12px 12px 50px 12px;

	    h1{
	    	color: $h1-font-color;
	    }

	    p{
	    	padding-bottom: 12px;
	    	font-size: 16px;
	    	max-width: 400px;
	    	text-align: center;
	    	margin: 0 auto;
	    }

	    .forgot_password{
	    	margin-bottom: 12px;
	    }
	    .mdl-cell{
	    	margin-left: 0px;
	    	margin-right: 0px;
	    }
	}

	.mdl-textfield {
	    color: #BFBFBF;
 
		&.is-dirty {
		    color: #333;
		}
		
		&__input {
		    padding-left: 3px;
		    font-family: $primary-font-face;
		    font-size: 14px;
		}

		&.is-focused,
		&.is-dirty {
			.mdl-textfield__label{
				color:$brand-color !important;

			}
		}

		.mdl-textfield__label:after {
	    	background-color: $brand-color !important;
		}
	}

	.material-icons {
	    font-size: 12px;
	}

	.login-button {
	    font-weight: 700;
	    font-size: 16px;
		width: 100%;
    	max-width: 300px;
		background-color: #D9220C;
		color: #fff;
		transition: .3s ease-in-out;
		&:hover {
			background-color:  #A91900;
		}
	}
	#cancel-btn {
		background-color: $brand-color-btn-secondary;
		transition: .3s ease-in-out;
		&:hover {
			background-color:  $brand-color-btn-secondary-hover;
		}
	}

	#register-btn {
		width: 100%;
    	max-width: 300px;
		background-color: #262626;
		color: #fff;
		transition: .3s ease-in-out;
		&:hover {
			background-color:  #666666;
		}
	}
}

.register {
	h1{
		text-align: center;
	}
}



