/*
 * Flex UX Framework
 *
 * Filename: checkout-split-controls.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.checkout-container{
	.checkout-split-row {
		border-bottom: none;

		@include mq("tablet", "min"){
		    padding: 20px;
		}

		&--single-split {
			@include mq("tablet", "max"){
		    	flex-flow: column;
			}
		}

		.checkout-split-cell--controls-top{
			justify-content: flex-start;
		}

		.checkout-split-cell--controls-top, .checkout-split-cell--controls-bottom {
		    flex: 1 1 100%;

		    @include mq("tablet", "max"){
		    	justify-content: center;
			    flex-wrap: wrap;
				flex-flow: column;
			}

			button:not(:last-child){
			    @include mq("tablet", "min"){
				    margin-right: 20px;
			   }
			}
		}
	}


	.checkout-split-row-wrap{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;

		@include mq("tablet", "max"){
			flex-flow: column;
		}


		.checkout-split-row {
			flex: 1;
			&.checkout-split-row--controls {
				&:nth-of-type(1) {
					min-width: 205px;
					justify-content: flex-start;
				}

				&:nth-of-type(2) {
					min-width: 191px;
					justify-content: flex-end;
				}

				.mdl-button{
					margin: 5px;
					font-family: $primary-font-face;
				}
				
				.btn--primary{
					font-weight: 700;
	    			font-size: 16px;
					//width: 100%;
    				//max-width: 300px;
					background-color: #D9220C;
					color: #fff;
					transition: .3s ease-in-out;
					&:hover {
						background-color:  #A91900;
					}
				}
			}

		    @include mq("phone-wide", "max"){
			    padding: 0px;
		   }

		}
	}
}