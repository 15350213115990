/*
 * Flex UX Framework
 *
 * Filename: _configs.scss
 * Type:     Stylesheet Configs
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


//
// General: Structure
// 

$body-max-width:                    1175px;


//
// General: Color
//

$brand-color:                       #D9220C;
$brand-color-secondary:             #A91900;

$brand-color-btn-primary:           $brand-color;
$brand-color-btn-primary-hover:     #A41A09;
$brand-color-btn-secondary:         #262626;
$brand-color-btn-secondary-hover:   #000;
$brand-color-btn-light:         #fff;
$brand-color-btn-light-hover:   #D9220C;

$accent-color: 						#009639;
$link-color:						$brand-color;
$link-hover: 						#A41A09;

$primary-font-color:                $brand-color;
$secondary-font-color:				#000;
$h1-font-color:                     #000;
$h2-font-color:                     #000;


//
// General: Font
// 

$h1-font-size:                      30px;
$h2-font-size:                      21px;
$primary-font-face:             	'Open Sans', 'sans-serif';
$secondary-font-face:        	   	'Open Sans', 'sans-serif';
$drawer-font:                    	$primary-font-face;

//
// Header
//

$header-background:                 #fff;
$header-top-background:				#262626;
$header-height:                     160px;
$header-top-height:                 115px;
$header-main-height:                50px;
$top-bar-height:					45px;
$header-mobile-height:              56px;
 
$header-tablet-height:              56px;
$header-phone-height:               56px;
/*$header-logo-padding:               10px 10px 10px 27px;*/
$header-logo-padding:               10px;
$header-minicart-badge-color:       #000;
$header-minicart-badge-text-color:  #FFF;
$header-minicart-badge-border:      none; 
$header-top-link-hover-border:      2px solid rgba(255,255,255,0.5);
$header-icons-color:                $brand-color;
$header-search-font-color:          $primary-font-color;
$header-search-underline-color:     $header-icons-color;


//
// Mobile Drawer Menu
//

$drawer-mobile-background:          #fff;


//
// Footer
//

$footer-background:                 #000;