/*
 * Flex UX Framework
 * 
 * Filename: product_card.scss
 * Type:     Component Stylesheet
 *
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

// @TODO: factor media queries, convert to mixin, push margin attributes to parent
.page-body .mdl-tabs .products-tab-panels .grid-cell {
	max-height: 304px;
	width: 300px;
	max-width: 300px;
	flex-basis: 300px;
}

.page-body .grid .grid-cell.product-cell,
.page-body .grid .grid-cell.new-cell {
	width: 219px;
	max-width: 219px;
	flex-basis: 219px;
	border-top: none;


	@include mq("tablet", "max") {
		width: calc(50% - 24px);
		flex-basis: calc(50% - 24px);
	}
}

// .page-body .grid .grid-cell.product-cell:nth-child(n+3), 
// .page-body .grid .grid-cell.new-cell:nth-child(n+3) {

// 	@include mq('tablet-small', 'max'){
// 	    display: none;
// 	}
// }

// .page-body .grid .grid-cell.product-cell:nth-child(n+4), 
// .page-body .grid .grid-cell.new-cell:nth-child(n+4) {
// 	@media only screen and (max-width: 994px) {
// 		display: none;
// 	}
// }

.product-card,
.product-cell {
	border-top: none;
	position: relative;
	background: white;
	box-sizing: border-box;
	overflow: hidden;
	display: flex;
	flex-flow: column nowrap;
	justify-content: space-between;
	flex-basis: 219px;
	flex: 1 0 219px;
	width: 219px;
	text-transform: uppercase;
	text-align: center;
	font-size: 11px;
	max-width: 219px;
	padding: 20px 10px;
	max-width: 212.5px;
	border-radius: 5px;

	@include mq('tablet', 'max') {
		width: calc(50% - 24px);
		flex-basis: calc(50% - 24px);
	}

	@include mq('phone-wide', 'max') {
		// height: 250px;
		font-size: 12px;
		// padding: 20px 10px;
	}

	@include mq('phone', 'max') {
		font-size: 13px;
	}

	&--spotlight {
		max-width: 295px;
		flex-basis: 295px;
		padding: 30px;

		@include mq('phone-wide', 'max') {
			padding: 20px 10px;
		}

		&:nth-child(n+2) {
			@include mq('phone-wide', 'max') {
				/*commented out - kevin */
				/*display: none;*/
			}
		}
	}

	a {
		text-decoration: none;
		cursor: pointer;
	}

	.product-card-image {
		padding-bottom: 0;
		max-height: 184px;
		margin: auto;
		max-width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;

		&__image {

			@include mq('phone-wide', 'min') {
				max-height: 100%;
			}

			max-width: 125px !important;
			max-height: 125px !important;
			display: block;
			width: auto;
			height: auto;
			padding-left: 20px;
			padding-right: 20px;
			padding-bottom: 20px;
		}
	}

	.name {
		font-size: 14px;
		font-family: $secondary-font-face;
		color: #000;
		font-weight: 700;
		line-height: 1.15em;
		overflow: hidden;
		//display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		letter-spacing: normal;
		margin-bottom: 10px;
		text-overflow: ellipsis;
		height: 48px
	}

	.price {
		font-family: $secondary-font-face;
		// margin-top: 7px;
		margin-top: 0px;
		font-size: 14px;
		color: $brand-color;
		font-weight: 700;
	}

}

//.product-card, .product-cell 

.product-cell {
	max-height: 304px;
	max-width: 300px;
	border-top: none;

	@include mq('phone-wide', 'min') {
		padding: 30px;
	}

	@include mq('tablet', 'max') {
		width: calc(50% - 24px) !important;
		flex-basis: calc(50% - 24px) !important;
	}

	.img-container {
		margin: auto;
		max-width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		max-height: 184px;
	}
}