/*
 * Flex UX Framework
 *
 * Filename: checkout_multibilltoaddr.scss
 * Type:     Template Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.checkout-container {
    display: flex;
    flex-wrap: wrap;

    @include mq("tablet", "min") {
    	margin: 20px;
  	}

  	i {

  		&.material-icons {
  			color: $brand-color;
  		}
  	}

  	.checkout-main {
  		display: flex;
		  flex-flow: column;
      width: 100%;

		@include mq("tablet", "min") {
        flex: 75 1;
        margin-right: 20px;
        height: 100%;
  		}

  		&-top {
  			margin-bottom: 20px;
        background: #fff;
  		}

  		&-bottom {
  			flex: 1 1 100%;
        background: #fff;
  		}

		/* PROMO CODE CODE */
		.checkout-basket-promo{
			display: flex;
			padding: 24px;
			flex-direction: column;

			.text-wrapper{
				display: flex;
				align-items: center;
				max-width: 300px;
			}

			.mdl-textfield {
				width: 100% !important;
				margin: 0px 15px;
			}

			button{
				max-width: 250px;
				color: #fff;
			}
			.btn--primary {
				font-size: 16px;
				font-family: $secondary-font-face;
				padding: 0px 15px;
				transition: background 0.15s;
				height: 45px;
				//letter-spacing: 1px;
				font-weight: 700;
				border: none;
				background-color: $brand-color-btn-primary;
				border-radius: 5px;
				// color: #3B3B3B;
				&:hover {
					background-color: $brand-color-btn-primary-hover!important;
				}
				
				@include mq('tablet', 'max') { 
				width: 100%;
				margin: auto;
				}
		
				@include mq("phone-wide", "max") {
					// min-width: 202.5px;
					width: 100%;
				}
			 }
		}

      p{
        font-size: 13px;
        line-height: 20px;
      }
  	}

  	.checkout-side {
  		flex: 25 1;
        @include mq("tablet", "max") {
          margin-top: 10px;
        }
  		&-top {
  			flex-flow: column;
			   padding: 0;

			&--addresses {
				padding: 16px!important;
				min-height: 125px;

				h2 {
					margin-bottom: 0;
					padding-left: 0;
					padding-right: 0;
					padding-top: 0;
					padding-bottom: 8px !important;
				}
			}
  		}

  		&-bottom {
  			display: flex;
			flex: 1 1 100%;
			flex-flow: column;
			padding: 0;
			margin-top: 20px;
  		}
  	}
}

