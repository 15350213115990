/*
 * Flex UX Framework
 *
 * Filename: header.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


// NOTE: This file makes exceptions to the standard skinning process by determining its
//       own position. This is because the header is in a fixed position at all times.

header {
	position: fixed;
	width: 100%;
	//	background: $brand-color;
	background: $header-background;
	z-index: 99;
	//	height: $header-height;
	@include mq('phone-small', 'max') {
		width: 100%;
	}  


	&.header-nav-container--landing {
		img {
			bottom: unset !important;
		}

		.main-nav {
			height: 100%;
		}
	}

	@include mq('tablet', 'max') {
		height: 56px;
		border-bottom: 1px solid #e6e6e6;
		display: flex;
		align-items: center;
	}

	.nav-1 {
		height: $top-bar-height;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		background: $header-top-background;

		.welcome {
			padding: 4px 10px 4px 158px;
			font-size: 13px;
			text-transform: none;
			display: flex;
			align-items: flex-end;
			justify-content: flex-end;
			background: $header-top-background;

			span {
				text-transform: capitalize;
				display: inline-block;
				font-size: 14px;
				font-weight: 400;
				color: #fff;
			}

		}

		@include mq('tablet', 'max') {
			display: none;
		}

		ul {
			padding: 0px 5px;
			margin: 0;
			height: 100%;
			display: flex;
			align-items: flex-end;
			justify-content: flex-end;

			li {
				display: flex;
				align-items: center;
				justify-content: flex-end;

				font-size: 14px;
				font-weight: 700;
				list-style-type: none;
				
				cursor: pointer;

				a {
					display: flex;
					align-items: center;
					padding: 0 12px;
					height: 45px;

					color: #fff;
					font-size: 14px;
					font-weight: 400;
					text-decoration: none;
					text-transform: capitalize;

					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}


	.site-header {

		/*height: $header-top-height;*/

		background: $header-background;
		font-weight: 400;
		font-family: $primary-font-face;
		font-size: 10px;
		display: flex;
		/*justify-content: space-between;*/
		justify-content: flex-start;
		align-items: center;
		height: 115px;
		border-bottom: 1px solid #e5e5e5;

		.desktop-logo {
			display: flex;
			align-self: flex-start;

			/*height: $header-top-height;*/

			width: 160px;

			.logo {
				max-width: none;
				max-height: none;
				/*height: 32px;*/
				width: 160px;
				padding-left: 25px;
				//margin-bottom: 4px;
				position: absolute;

				height: $header-top-height;
				display: flex;
				align-items: center;


				@include mq('tablet', 'max') {
					margin-bottom: 0px;
					padding-left: 10px;
					position: relative;
					top: unset;
					height: 38px;

				}



				@include mq('tablet', 'max') {

					max-height:calc(#{$header-tablet-height} - #{$header-logo-padding});

					//	margin-left: $header-logo-padding;
					bottom: unset;
					top: 0;

				}

				@include mq('phone-wide', 'max') {

					max-height:calc(#{$header-phone-height} - #{$header-logo-padding});

					//	margin-left: $header-logo-padding;
					max-width: 140px;

				}
			}
		}

		@include mq('tablet', 'max') {
			display: none;
		}

		div {
			display: flex;
		}

		&__welcome {
			padding: 7px 0;
			padding-left: 158px;
			font-size: 13px;
			text-transform: none;

			span {
				margin-left: 25px;
				color: #FFFFFF;
				font-size: 14px;
				font-weight: 400;
				text-align: right;

				&:first-of-type {
					font-weight: 700;
				}
			}
		}

		&__nav-menu {
			margin: 0;
			margin-right: 30px;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: flex-end;

			li {
				display: inline-block;
				list-style-type: none;
				font-size: 14px;
				padding: 0px 12px;
				cursor: pointer;
				font-weight: 700;
				height: 100%;
				display: flex;
				align-items: center;

				&:hover {
					a {
						text-decoration: underline;
						color: #E6F0F4;
					}
				}

				a {
					text-decoration: none;
					text-transform: capitalize;
					display: inline-block;
					color: #4A4A4A;
					font-size: 15px;
					font-weight: 400;
				}
			}

		}

		.site-header__left {
			position: absolute;
			left: 0;
			top: 0;
			max-height: $header-top-height;

			@media only screen and (min-width: #{$body-max-width}) {
				left: calc((100vw - #{$body-max-width}) / 2);
			}

			.mdl-textfield {
				padding: 0;
			}
		}

	}

	.main-nav {
		display: flex;
		flex-flow: row nowrap;
		//align-items: center;
		justify-content: space-between;
		font-weight: 700;
		padding-left: 0px;
		padding-right: 35px;
		height: $header-main-height;
		color: #fff;
		font-family: $primary-font-face;
		background: $header-background;
		// border-top: 1px solid #DBDBDB;
		// border-bottom: 1px solid #DBDBDB;

		@include mq('tablet', 'max') {
			height: $header-tablet-height;
			padding-right: 49px;
		}

		@include mq('phone-wide', 'max') {
			height: $header-phone-height;
			padding-right: 49px;
		}

		&__left {
			display: flex;
			align-items: flex-start;
		}

		&__right {
			display: flex;
			align-items: center;
		}

	}

	.main-nav__right {
		flex-flow: row;
		//flex-direction: column;
		//align-items: flex-end;

		&.desktop {
			/*made changes here - kevin -05.21.21*/
			/*padding-right: 65px;*/
			position: absolute;
			right: 60px;
			padding-bottom: 10px;
		}

		&.mobile {
			@include mq('tablet', 'min') {
				position: absolute;
				right: 10px;
				top: 40px;
			}
		}

		.mdl-button--search {
			top: 3px;
			bottom: 0;
			left: 0;
			// margin-left: 5px;

			&:hover {
				background-color: #F7F7F7;
			}

			.material-icons {
				font-size: 24px;
				height: 24px;
				width: 24px;
			}

		}

		@media all and (-ms-high-contrast: none),
		(-ms-high-contrast: active) {

			/* IE10+ CSS styles go here */
			.mdl-button--search {
				top: auto !important;
				bottom: 17px !important;
			}

		}

		.mdl-textfield--expandable {
			padding: 0;
			// width: 215px;
			display: flex;
			align-items: center;
			// border: 1px solid #DBDBDB;
			border-radius: 25px;
			margin-left: 12px;
		}

		#searchField {
			/*min-width: 180px;*/
			border-bottom: none;
			padding-left: 10px;
		}

		.search-desktop {
			display: flex;
			margin: 0;
			align-items: center;
			//padding-right: 10px;
		}

		.search-textfield-container {
			position: relative;
			height: 30px;
			border-radius: 3px;
			// background: white;

			.mdl-textfield {
				&__expandable-holder {
					margin-left: 25px;
					//border-bottom: 1px solid $brand-color;
				}
			}

			.material-icons {
				color: $brand-color;
				// margin-top: 3px;
				margin-left: 3px;
			}

			.mdl-textfield__input {
				color: #000 !important;

			}

			@include mq('tablet', 'max') {
				display: none;
			}

		}

		.material-icons {
			margin-right: 1px;
			color: $header-icons-color;
			transform: translate(-14px, -11px);
		}

		.cart {
			margin-left: 30px;
			top: 45px;
			position: relative;


			@include mq("tablet", "max") {
				margin-left: initial;
				top: auto;
				position: absolute;
				right: 50px;
				bottom: 10px;
			}

			.cart-container {
				width: 34px;
				height: 34px;

				&:hover {
					background-color: #F7F7F7;
				}

				.material-icons::after {
					border: $header-minicart-badge-border;
					width: 12px;
					height: 12px;
					top: -3px;
					right: -1px;
					font-size: 9px;
					background: $header-minicart-badge-color;
					color: $header-minicart-badge-text-color;
				}

			}

			.mdl-menu {
				&__container {
					right: -15px;
					top: 44px;
					max-height: calc(100vh - 108px);
					margin-top: 8px;

					@include mq('tablet', 'min') {
						height: 295px !important;
					}
					@include mq('tablet', 'max') {
						left: -370px;
					}
					@include mq('phone-wide', 'max') {
						left: -316px;
					}
					@include mq('phone', 'max') {
						left: -256px;
					}
					@include mq('phone-small', 'max') {
						left: -236px;
					}

					.shopping-cart {
						padding: 0;
						width: 430px;
						max-width: 100vw;
						display: flex;
						flex-flow: column nowrap;
						color: #000;

						.cart-header {
							padding: 0 20px;
							border-bottom: 1px solid rgba(226, 226, 226, 0.5);
							height: 80px;
							display: flex;
							align-items: center;
							box-sizing: border-box;

							@include mq('tablet', 'max') {
								height: 50px;
								min-height: 50px;
								font-weight: 700;
								text-transform: capitalize !important;
							}

							.cart-title {
								font-size: 23px;
								line-height: 1.75;
								font-weight: 700;
								text-transform: capitalize;

								@include mq('tablet', 'max') {
									font-size: 19px;
								}

							}

						}

						.cart-item-container {
							overflow-y: auto;
							flex-grow: 1;
							padding: 0 20px;
							max-height: 472px;

							.cart-item {
								display: flex;
								align-items: center;
								height: 135px;
								box-sizing: border-box;
								position: relative;

								button {
									.material-icons {
										color: $brand-color;
									}
								}

								.cart-item-img {
									display: flex;
									width: 85px;

									img {
										margin: auto;
										max-height: 85px;
										max-width: 85px;
									}

								}

								.cart-item-text {
									flex: 1;
									display: flex;
									flex-wrap: wrap;
									margin-left: 30px;
									font-size: 13px;

									.cart-item-name {
										text-transform: uppercase;
										flex: 2 100%;
										margin-bottom: 25px;

										a {
											font-weight: 700;
											font-size: 13px;
											line-height: 16px;
											text-decoration: none;
											color: #000;

											&:hover {
												color: unset;
											}
										}

									}

									.cart-item-quantity {
										flex: 1 50%;
										font-weight: 400;
										color: #000;
									}

									.cart-item-price {
										flex: 1 50%;
										color: $brand-color-secondary;

										.price {
											font-weight: 700;
											color: $brand-color;
										}
									}
								}
							}
						}

						.cart-footer {
							border-top: 1px solid rgba(226, 226, 226, 0.5);
							display: flex;
							justify-content: space-between;
							align-items: center;
							padding: 0 20px;
							height: 80px;

							.checkout-btn {
								// padding-bottom: 5px;
								padding-top: 5px;
								height: 38px;
								background-color: #D9220C;
    							color: #fff;
    							transition: .3s ease-in-out;

    							//@include mq("tablet", "max") {
    							//    width: 80%;
    							//    margin: auto;
    							//}

    							&:hover {
    							    background-color: #A91900;
    							}
							}

							@include mq('tablet', 'max') {
								height: 59px;
								min-height: 59px;
							}

							.cart-subtotal {
								font-size: 18px;
								display: flex;
								font-weight: 700;
								flex-direction: column;
								justify-content: center;
								align-items: flex-start;
								color: $brand-color;

								.price {
									font-weight: 700;
									color: $brand-color;
								}

								@include mq('tablet', 'max') {
									font-size: 15px;
								}

							}
						}

					}

				}

			}

		}

		.side-nav {
			ul {
				padding: 0px 5px;
				margin: 0;
				height: 100%;
				display: flex !important;
				list-style: none;

				li {
					display: flex;
					justify-content: center;
					list-style-type: none;
					font-size: 14px;
					padding: 0px 12px;
					cursor: pointer;
					font-weight: 700;
					//height: 100%; 
					display: flex;
					align-items: center;

					&:hover {
						a {
							color: #008733;
						}
					}

					a {
						text-decoration: none;
						text-transform: capitalize;
						display: inline-block;
						color: #4A4A4A;
						font-size: 14px;
						font-weight: 400;
					}
				}
			}
		}
	}

	.main-nav__left {
		flex-flow: column;
		// padding: 0px 10px;

		.logo {
			display: flex;
		}

		@include mq('tablet', 'max') {
			.logo {
				display: none;
			}

			display: flex;
			flex-flow: row;
			align-items: center;

		}

		@include mq('phone-wide', 'max') {
			justify-content: space-between;
		}



		.desktop-nav {
			@include mq('tablet', 'max') {
				display: none !important;
			}

			display: flex;
			flex-flow: row wrap;
			padding-top: 9px;
			margin: 0px;
			padding: 0px;
			position: relative;

			ul:first-of-type {
				position: absolute;
			}

			&>ul {
				padding-left: 30px;
				flex: 1 1 100%;
				display: flex;
				height: 48px;
				width: 99vw;
				margin: 0px;
			}

			&>ul>li {
				position: relative;
				display: inline-block;
				list-style: none;
				height: 48px;

				&:nth-child(7) {
					margin-left: auto;
				}

			}

			&>ul>li[data-subnav] {
				position: relative;
				display: inline-block;
				list-style: none;
				padding-left: 23px;
				padding-right: 10px;

				.sub-nav-item {
					a {
						color: #000;
						font-size: 14px;
						text-align: left;
						font-weight: 400;

						&:hover {
							color: $link-color;
						}
					}
				}

				.nav-handle {
					// text-align: left;
					position: relative;
					padding-right: 33px;
				}

				>.nav-handle {

					::after {
						font-family: 'Material Icons';
						content: '\e5cb';
						display: inline-block;
						transform: rotate(-90deg);
						font-size: 24px;
						color: $brand-color;
						position: absolute;
						clip: rect(6px, 22px, 32px, 0px);
						font-weight: 300;
					}

					//&:hover{
					//	::after{
					//		color: #CCE1E9;
					//	}
					//}
				}



				.sub-nav {
					z-index: 10;
					padding: 0;
					display: none;
					opacity: 0;
					padding: 8px 0px;

					.sub-nav-item {
						position: relative;
						margin: 0;
						cursor: pointer;
						display: block;
						white-space: nowrap;
						min-width: 200px;
					}

					.nav-handle {
						padding: 0 24px;
						height: unset;

						&:hover {
							background-color: #F7F7F7;

							>a {
								color: $link-color;
							}
						}
					}

				}

				.sub-nav [data-subnav] {
					&>.nav-handle {
						padding-right: 45px;
						width: 100%;

						// &::after {
						// 	font-family: 'Material Icons';
						// 	content: '\e5cc';
						// 	display: inline-block;
						// 	font-size: 24px;
						// 	color: $brand-color;
						// 	position: absolute;
						// 	// right: 10px;
						// 	clip: rect(6px, 22px, 32px, 0px);
						// 	text-decoration: none !important; 
						// } 

						a {
							&:hover {

								::after {
									text-decoration: none !important;

								}
							}

							&::after {
								font-family: 'Material Icons';
								content: '\e5cc';
								display: inline-block;
								font-size: 24px;
								color: $brand-color;
								position: absolute;
								right: 80px;
								clip: rect(6px, 22px, 32px, 0px);
								text-decoration: none !important;
							}
						}
					}

					.subSubNavContainer {
						//display: none;
						position: absolute;
						top: 0;
						left: 99.5%;
					}

				}

			}

			&>ul>li {
				.nav-handle {
					border-radius: 0;
					padding: 0;
					display: flex;
					flex-flow: column;
					justify-content: center;
					height: 48px;
					padding-left: 14px;
					padding-right: 14px;

					&:hover {
						background-color: #F7F7F7;

						a {
							color: $brand-color;
						}
					}

					&:nth-of-type(3) {
						width: 100px;
					}
				}

				a {
					color: #000;
					text-transform: none;
					text-decoration: none;
					font-family: $primary-font-face;
					font-size: 15px;

				}

				.nav-handle-line-1 a {
					font-weight: 600;
					flex-shrink: 1;

					span {
						padding: 0px;
						flex-shrink: 1;
						white-space: nowrap;
					}
				}

				// .nav-handle-line-1-3 a{
				// 	pointer-events: none;
				// }

			}
		}

		.mobile-logo {
			display: none;
			max-width: none;
			max-height: none;
			height: 47px;
			padding-left: 0px;

			@include mq('tablet', 'max') {
				display: flex;
				margin-bottom: 0px;
				padding-left: 10px;
				position: relative;
				top: unset;
				height: 38px;

			}

			@include mq('tablet', 'max') {
				max-height:calc(#{$header-tablet-height} - #{$header-logo-padding});

				//	margin-left: $header-logo-padding;
				bottom: unset;
				top: 0;

			}

			@include mq('desktop', 'max') {
				max-height:calc(#{$header-phone-height} - #{$header-logo-padding});


				//	margin-left: $header-logo-padding;
				max-width: 140px;

			}

		}

	}
}


.secondary-nav {
	height: $header-top-height;
	background: $brand-color;
	font-weight: bold;
	font-family: $primary-font-face;
	font-size: 10px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-right: -10px;

	@include mq('tablet', 'max') {
		display: none;
	}

	div {
		display: flex;
	}

	&__nav-menu {
		margin: 0;
		padding-inline-start: 0px !important;
		padding-left: 0px;
		height: 100%;
		display: flex;
		align-items: center;

		li {
			display: inline-block;
			list-style-type: none;
			font-size: 13px;
			padding: 0px 12px;
			cursor: pointer;
			font-weight: 700;
			height: 100%;
			display: flex;
			align-items: center;

			&:hover {
				background: $brand-color-secondary;

				a {
					color: #CCE1E9;
				}
			}

			a {
				text-decoration: none;
				text-transform: capitalize;
				display: inline-block;
				color: #FFFFFF;
				font-size: 14px;
				font-weight: 700;
			}

		}

	}

}

.mobile-drawer-nav {
	position: fixed;
	z-index: 100;
	pointer-events: none;
	font-family: $primary-font-face;

	.drawer-logo-container {
		background: $drawer-mobile-background;
		height: $header-mobile-height;
		padding: 0px;
		display: flex;
		align-items: center;
		border-bottom: 1px solid #e6e6e6;

		.close-menu {
			position: absolute;
			right: 5px;
			top: 18px;
			color: $link-color !important;
			cursor: pointer;

			&:hover {
				color: $link-hover !important;
			}
		}

		img {
			height: 38px;
			margin-left: 10px !important;
			max-height: calc(56px - 10px);

			@include mq('desktop', 'max') {
				max-height: calc(#{$header-phone-height} - #{$header-logo-padding});
				//	margin-left: $header-logo-padding;
				max-width: 140px;
			}
		}
	}

	.secondary-nav a {
		color: red !important;
	}

	.mobile-nav-page-controller {
		margin-bottom: 10px;
		position: relative;
		overflow: hidden;
		-webkit-transition: height 0.3s;
	}

	.mobile-nav-page {
		position: absolute;
		width: 100%;
		-webkit-transition: margin 0.3s;
	}

	.mdl-layout__drawer {
		&.is-visible {
			transform: translateX(0);
			pointer-events: all;
		}

		background: #fff;
		font-weight: 400;
		border: none;
		text-transform: capitalize;
		width: 280px;
		transform: translateX(-280px);

		&-button {
			color: $header-icons-color;
			top: 1px;
			left: auto;
			right: -4px;
			pointer-events: all;

			height: 50px;
			justify-content: center;
			align-items: center;


			@include mq('tablet', 'max') {
				display: flex;
			}
		}

	}

	.mdl-navigation {
		padding-top: 0;
		font-size: 14px;

		ul {
			font-size: 14px;
			padding-left: 20px;
		}

	}

	ul {
		.mobile-nav-handle {
			a {
				color: #000;
				font-weight: 700;
				font-size: 14px;
				text-transform: capitalize;
			}

		}

		[data-subnav] {
			i.material-icons {
				color: $brand-color;
			}

		}

		li {
			display: flex;
			margin-bottom: 10px;

			a {
				font-weight: 700;
			}
		}

	}


	.back-button {
		cursor: pointer;
		padding-left: 20px;
		margin-bottom: 8px;
		display: flex;
		line-height: 24px;
		color: #000;
		font-weight: 700;

		i.material-icons {
			color: $brand-color;
			margin-right: 10px;
		}

	}

	.sub-nav-title {
		font-weight: bold;
		color: #000;

		&:before {
			content: "•";
			font-size: 23px;
			line-height: 25px;
			color: $brand-color;
			margin-right: 6px;
		}

	}

	.page-right {
		margin-left: 100%;
	}

	.page-left {
		margin-left: -100%;
	}

	.search-textfield-container {
		padding: 0 20px;
		padding-right: 15px;

		.mdl-textfield {
			padding: 27px 0;
			width: 100%;
			margin-left: -11px;
			padding-top: 14px !important;
		}

		.mdl-textfield__input {
			position: relative;
			top: 6px;
			text-align: left;
		}

		input {
			width: calc(100% - 40px);
			margin-left: 40px;
			border-bottom: 1px solid lightgray;
			border-radius: 0;
		}

		.mdl-textfield__label {
			width: calc(100% - 40px);
			margin-left: 40px;
		}

		.mdl-textfield.is-dirty,
		.mdl-textfield.is-focused {
			.mdl-textfield__label {
				color: $brand-color !important;
			}

		}

		i {
			color: $brand-color;
		}
	}
}

nav ul li a {
	text-decoration: none;
}

.page-header {
	margin-top: ($header-height);

	@include mq("tablet", "max") {
		margin-top: $header-mobile-height;
	}

	//@include mq("tablet", "max") {
	//	flex-flow: column-reverse nowrap;
	//}
}

.page-header,
.page-header .category-header-img {
	@include mq('phone', 'max') {
		// 		height: 100px; 
	}
}


.mdl-menu__container {
	@include mq('tablet', 'max') {
		// right: 21px !important;
		right: initial !important;
	}
}

.Quick {
    color: #000 !important;
    font-weight: 700 !important;
}