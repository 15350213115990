/*
 * Flex UX Framework
 *
 * Filename: contact_us.scss
 * Type:     Template Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.contact-us {

	main {
		background: #fff !important;
	}

    .page-header {
        height: 0;
    }

	.page-body {
    	max-width: 600px;
        margin: 0px auto;
        padding: 12px 12px 50px 12px;
   		font-size: 14px;
    	line-height: 1.5em;
    	color: #000;
    	font-family: $primary-font-face;
	}
}