/*
 * Flex UX Framework
 *
 * Filename: checkout-addresses-multi-controls.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */
.checkout-address-controls .mdl-button:not(:last-child) {
	@include mq("tablet", "max"){
	    margin-bottom: 10px;
	}
}

.checkout-address-controls-container {
	display: flex;

	@include mq("tablet", "min"){
    	padding: 20px;
	}

	@include mq("tablet", "max"){
	   	padding: 10px;
	    flex-wrap: wrap;
	    flex-flow: column;
	}

	.checkout-address-controls-split {
	    display: flex;
	    justify-content: flex-start;
	    flex: 2 2;

		.btn--secondary{
			color: #fff;
			font-size: 16px;
			margin-top: 30px;
			font-weight: 700;
			//width: 100%;
    		//max-width: 300px;
            background-color: $brand-color-btn-secondary;
		    transition: .3s ease-in-out;
		    &:hover {
			    background-color:  $brand-color-btn-secondary-hover;
		    }

			@include mq("tablet", "max"){
				width: max-content;
				margin: auto auto 10px;
			}
		}

		.btn--primary{
			margin-top: 30px;
			font-weight: 700;
	    	font-size: 16px;
			//width: 100%;
    		//max-width: 300px;
			background-color: #D9220C;
			color: #fff;
			transition: .3s ease-in-out;
			&:hover {
				background-color:  #A91900;
			}

			@include mq("tablet", "max"){
				width: max-content;
				margin: auto auto 10px;
			}

		}

	    @include mq("tablet", "max"){
		    flex-wrap: wrap;
		    flex-flow: column;
		}

		button{
			margin-bottom: 10px;
		}

	    button:not(:last-child) {
	    	@include mq("tablet", "min"){
		    	margin-right: 20px;
		    }
		}
	}

	.checkout-address-continue-split {
	    display: flex;
	    flex: 1 1;
	    justify-content: flex-end;

	    button {
		   width: 250px;
		}	

	    @include mq("tablet", "max"){
	    	button{
				width:100%;
	    	}
		}

		.btn--primary{
			margin-top: 30px;
			margin-bottom: 10px;
			font-weight: 700;
	    	font-size: 16px;
			//max-width: 300px;
			//width: 100%;
			background-color: #D9220C;
			color: #fff;
			transition: .3s ease-in-out;
			&:hover {
				background-color:  #A91900;
			}

			@include mq("tablet", "max"){
				width: max-content;
				margin: auto auto 10px;
			}

		}
	}
}