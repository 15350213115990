/*
 * Flex UX Framework
 *
 * Filename: wishlist-content.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.wishlist{
	 main {
	    background: #fff!important;
	    min-height: calc(100vh - 65px) !important;
	}

	form{
		.f-field{
			textarea,input{
				@include mq("phone-wide", "max"){
					width: 100%;
				}
			}
		}
		.f-row, .f-buttons{
			margin-bottom: 10px;
		}
		.f-buttons{
			display: flex;

			// input{
			// 	padding: 2px 5px;
			// 	width:100px;
			// 	// border: none;
				
			// 	@include mq("phone-wide", "max"){
			// 		width: 50%;
			// 	}
			// }
		
			 .btn--primary {
				font-size: 16px;
				font-family: $secondary-font-face;
				padding: 0px 15px;
				transition: background 0.15s;
				height: 45px;
				width: 90px;
				font-weight: 700;
				border: none;
				background-color: $brand-color-btn-primary;
				border-radius: 5px;
				color: #fff;

				&:hover {
					background-color: $brand-color-btn-primary-hover!important;
				}
				
				@include mq('tablet', 'max') { 
				width: 100%;
				margin: auto;
				}
		
				@include mq("phone-wide", "max") {
					// min-width: 202.5px;
					width: 100%;
				}
			 }
		
			.btn--secondary {
				font-size: 16px;
				width: auto;
				background-color: #262626;
				color: #fff;
				transition: .3s ease-in-out;
				margin-top: 0px;
				padding-top: 4px;

				@include mq("tablet", "max") {
					width: 100%;
				}

				&:hover {
					background-color: #666666;
				}
			}
		}
	}

	.page-body{
    	max-width: 600px;
        margin: 0px auto;
        padding: 12px 12px 50px 12px;

		p:first-of-type{
			line-height: 18px;
			margin-bottom: 5px !important;
		}
		p:nth-child(3){
			margin-bottom: 8px;
		}
		a{
			font-size: 14px;
			margin-top: 8px;
			// color:$brand-color;
		}
		form.horizontal{
			input[name="emailAddr"]{
				border: 1px solid rgb(118, 118, 118);
				min-height: 30px;
			}
		}
	}

	.btn-continue-shopping{
		margin-top: 20px;
		background-color: #D9220C;
    	color: #fff;
    	transition: .3s ease-in-out;

    &:hover {
        background-color: #A91900;
    }
	}

	.wishlist-grid {
		padding-top: 24px;
		
		@include mq("tablet", "max") {
			justify-content: center;
		}

		@include mq("phone-wide", "max"){
		    padding: 15px;
		}

		textarea {
		    width: 100%;
		    min-height: 64px;
		    min-width: 200px;
		    margin-bottom: 10px;
		}


		.wishlist-product-cell {
			margin-bottom: 50px;

			@include mq('tablet','max'){
				margin-bottom: 10px;
			}
			.product-card{
				@include mq("phone-wide", "max"){
				    width: calc(100% - 28px);
				}
			}

			.mdl-checkbox {
			    float: left;
			    width: 0px;
			}
		}

		.wishlist-controls-cell{

			input{
				margin-top: 10px;
				text-align: center;
				height: 45px;
				font-family: $secondary-font-face;
			}
		}

	}

	.mdl-cell {
		font-size: 14px;
		margin-left: 0px;
		@include mq('tablet','max'){
			margin-bottom: 50px;
			margin-right: 0px;
		}

	}	
}

input.mdl-button[name="submitSaveNote_18869"] {
	margin: 0 auto;
	width: auto;
		background-color: #D9220C;
    	color: #fff;
    	transition: .3s ease-in-out;

    &:hover {
        background-color: #A91900;
    }
}
#submitAddToCart {
	margin: 10px auto 0;
	width: auto;
	background-color: #D9220C;
    color: #fff;
    transition: .3s ease-in-out;
	text-transform: capitalize;

		@include mq("tablet", "max") {
			width: 100%;
		}

    &:hover {
        background-color: #A91900;
    }
}
input.mdl-button[name="submitDeleteItems"] {
	margin: 20px auto 0;
	width: auto;
	background-color: #262626;
	color: #fff;
	transition: .3s ease-in-out;

	@include mq("tablet", "max") {
		width: 100%;
	}

&:hover {
	background-color: #666666;
}
}
input.mdl-button[name="submitClearWishList"] {
	margin: 20px auto 0;
	width: auto;
	background-color: #262626;
	color: #fff;
	transition: .3s ease-in-out;

	@include mq("tablet", "max") {
		width: 100%;
	}

&:hover {
	background-color: #666666;
}
}

