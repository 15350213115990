/*
 * Flex UX Framework
 *
 * Filename: item-add-buttons.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

.add-another-btn-container {
    padding: 10px 0;

    .add-another-btn {
        font-size: 14px;
        color: $secondary-font-color;
        font-family: $secondary-font-face;
        font-weight: 700;
        height: 45px;
        border-radius: 5px;

        .material-icons.add-icon{
            margin-top: -2px;
            font-size: 24px;
            color: $brand-color;
        }

        &:hover{
            background-color: #f7f7f7;
        }
    }
}

.add-to-list-buttons {
    padding: 30px 0 20px;
    border: 1px solid rgba(226,226,226,0.5);
    border-left: none;
    border-right: none;
    display: flex;

    @include mq("phone", "max") {
        flex-direction:column;
    }
}

.add-to-wishlist-btn, 
.add-to-cart-btn {
    height: auto;
    min-height: 45px;
    margin-bottom: 10px;

    @include mq("phone-wide", "max") {
        width: 100%;
        margin: 0;
        margin-bottom: 10px;
    }
}

.add-to-cart-btn {
    background-color: #D9220C;
    color: #fff;
    transition: .3s ease-in-out;

    @include mq("desktop", "max") {
        width: 100%;
    }
    //@include mq("tablet", "max") {
    //    width: 80%;
    //    margin: auto;
    //}

    &:hover {
        background-color: #A91900;
    }
}
.add-to-wishlist-btn {
    background-color: #262626;
    color: #fff;
    transition: .3s ease-in-out;

    @include mq("desktop", "max") {
        width: 100%;
    }
    //@include mq("tablet", "max") {
    //    width: 80%;
    //    margin-left: auto;
    //    margin-right: auto;
    //}

    &:hover {
        background-color: #666;
    }
}

#add-to-cart-form{
    margin: 0;
}