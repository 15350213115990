/*
 * Flex UX Framework
 *
 * Filename: myaccount_link.scss
 * Type:     Component Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */

.my-account{
    .mdl-tabs{
        &__tab {
            padding: 0px 15px;
            transition: background 0.15s;
            font-family: $primary-font-face;
            height: 48px;
            font-size: 13px;
            text-transform: capitalize;
            font-weight: 700;
            color: #000;
            
            &:hover{
                background: #F7F7F7;
                color: $link-color;
            }

            &.is-active{     
                color: #D9220C !important; 
            }

           @include mq("phone-wide", "max"){
                padding: 0 12px;
            }

            &::after {
           		background: $brand-color !important;
            	height: 2px;
            }
        }

        &__tab-bar{
            // background-color: #DBDBDB;
            border: 1px solid #E5E5E5;
            border-top: none;
        }
    }
}