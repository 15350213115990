/*
 * Flex UX Framework
 *
 * Filename: category-sub-list.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.sub-categories-container-desktop {
    width: 215px;
    min-width: 215px;
    order: 3;
    margin-right: 25px;
    z-index: 1;

    .sub-categories-header {
    	display: flex;
		flex-flow: row nowrap;
		align-items: center;
		padding: 0 24px;
		height: 62px;
		line-height: 62px;
		border-bottom: 1px solid rgba(226,226,226,0.5);
		background: white; 
		border-radius: 5px 5px 0px 0px;
		 
 
		i {
			color: $brand-color;
		}
 
		.sub-categories-title {
			text-transform: uppercase;
			margin-left: 12px;
			font-size: 14px; 
			font-weight: 700;
			color: $secondary-font-color;
			font-family: $secondary-font-face ;
		}
    }

    @include mq('tablet', 'max'){
    	display: none;
    }
}


.sub-categories-content-collapse-wrapper {

	.sub-categories-content {
    	background: white;
    	padding: 20px 24px;
    	/*line-height: 28px;*/
    	border-radius: 0px 0px 5px 5px;

    	a {
    		width: 100%;
			display: inline-block;
			text-decoration: none;
			font-family: $secondary-font-face;
		    font-size: 14px; 
		    font-weight: 400; 
 			color: $secondary-font-color;
 			padding-bottom: 12px;
 			line-height: 1.35em;

		    &:hover {
		    	text-decoration: underline;
		    	color: $link-hover;
		    }
    	}
	}

	@include mq('tablet', 'max'){
		width: 100%;
    	margin: 10px;
	}
	@include mq("phone-wide", "max"){
		    margin-top: 0px; 
	}
}

.sub-categories-header-mobile, 
.sub-categories-content-collapse-wrapper-mobile {

	@include mq("tablet", "max") {
		margin-top: 0;
	}

    @include mq('tablet', 'min') {
    	display: none;
    }

	@include mq('phone-wide', 'min'){
    	margin-bottom: 25px;
	}
}

.sub-categories-content-collapse-wrapper-mobile {
    border-radius: 0px;
}

.sub-categories-header-mobile {
	flex-flow: row nowrap;
    align-items: center;
	//border-bottom: 1px solid rgba(226,226,226,0.5);
    background: white;
    align-self: center;
    max-width: 45%;
    margin-bottom: 5px;
	border-radius: 5px;
	font-size: 14px;
	font-weight: 700;

    .sub-categories-title {
    	text-transform: uppercase;
    	font-size: 15px;
    	font-family: $secondary-font-face;
    	font-weight: 600; 
    	line-height: 12px;
		@include mq('tablet', 'max'){
			font-weight: 700;
			font-size: 14px;
		}
    }

    &:before {
    	font-family: 'Material Icons';
	    content: '\e896';
	    display: inline-block;
	    font-size: 22px;
	    color: $brand-color;
	    position: relative;
	    top: 1px;
	    left: -6px;
    }

	@include mq('tablet', 'max'){
		display: flex;
	    flex: 0;
	    width: 100%;
	    height: 45px;
	    line-height: 45px;
	    padding: 0 20px 0 15px;
	    margin: 0px 10px 5px 10px;
	    cursor: pointer;
	}
}

.filters-content-collapse-wrapper > .mdl-collapse__content, 
.sub-categories-content-collapse-wrapper > .mdl-collapse__content {
    @include mq("tablet", "min"){
    	margin-top: 0 !important;
    }
}