/*
 * Flex UX Framework
 *
 * Filename: checkout-addresses-continue.scss
 * Type:     Partial Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.checkout-container {
	.btn-main{

		&--continue-checkout {
			width: 100%;
			max-width: 300px;
			background-color: #D9220C;
			color: #fff;
			transition: .3s ease-in-out;
	
		&:hover {
			background-color: #A91900;
		}
		
		@include mq("tablet", "max") {
			margin: auto;
		}

		}

		@include mq("tablet", "min") {

			&--continue-checkout {
    			margin-left: 20px;
    			margin-right: 20px;
    			width: 250px;
    		}
    	}
    }
}