/*
 * Flex UX Framework
 *
 * Filename: checkout_payment.scss
 * Type:     Template Stylesheet
 * 
 * Copyright (C) 2019 Geiger, All Rights Reserved
 */


.checkout-payment {
	.continue-button-plp {
		margin: 20px 20px 0px;
		background-color: #D9220C;
    	color: #fff;
    	transition: .3s ease-in-out;
		width: 100%;

    	//@include mq("desktop", "max") {
        //width: max-content;
    	//}
    	//@include mq("tablet", "max") {
		//	margin-left: auto;
		//	margin-right: auto;
		//}

    	&:hover {
        background-color: #A91900;
    	}
	}

	.purchaseOrder {
		display: none;
	}

	.payment-columns {
		background: #fff;
	}
 
// 	input[name="purchaseOrder_0_7292"] {
// 		display: none;
// 	}
}